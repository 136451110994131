export const LOGOS = {
  BRAND: {
    LIGHT: {
      icon: "/images/logos/fts/ftsprime_new.png",
      small: "/images/logos/fts/ftsprime_new.png",
      medium: "/images/logos/fts/ftsprime_new.png",
      large: "/images/logos/fts/ftsprime_new.png",
    },
    DARK: {
      icon: "/images/logos/fts/ftsprime_dark.png",
      small: "/images/logos/fts/ftsprime_dark.png",
      medium: "/images/logos/fts/ftsprime_dark.png",
      large: "/images/logos/fts/ftsprime_dark.png",
    },
  },
  BROKER1: {
    LIGHT: {
      icon: "/images/logos/antos/icon.png",
      small: "/images/logos/antos/antoslogo.svg",
      medium: "/images/logos/antos/antoslogo.svg",
      large: "/images/logos/antos/antoslogo.svg",
    },
    DARK: {
      icon: "/images/logos/antos/icon.png",
      small: "/images/logos/antos/antos-dark.png",
      medium: "/images/logos/antos/antos-dark.png",
      large: "/images/logos/antos/antos-dark.png",
    },
  },
  BROKER2: {
    LIGHT: {
      icon: "",
      small: "",
      medium: "",
      large: "",
    },
    DARK: {
      icon: "",
      small: "",
      medium: "",
      large: "",
    },
  },
};
